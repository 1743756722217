<template>
    <path id="Vector (Stroke)_27" fill-rule="evenodd" clip-rule="evenodd"
        d="M184.519 756H276.031L310.547 825.033L276.031 894.065H184.513L150 824.891L184.519 756ZM191.232 766.871L162.154 824.902L191.238 883.194H269.312L298.393 825.033L269.312 766.871H191.232Z"
        fill="#E8D9AA" />
    <path id="Vector (Stroke)_28" fill-rule="evenodd" clip-rule="evenodd"
        d="M184.519 905H276.031L310.547 974.033L276.031 1043.07H184.513L150 973.891L184.519 905ZM191.232 915.871L162.154 973.902L191.238 1032.19H269.312L298.393 974.033L269.312 915.871H191.232Z"
        fill="#E8D9AA" />
    <path id="Vector_29" d="M280 154.74L317.37 80H417.114L454.484 154.74L417.114 229.48H317.37L280 154.74Z"
        fill="#D4A168" />
    <path id="Vector (Stroke)_29" fill-rule="evenodd" clip-rule="evenodd"
        d="M313.87 75H419.794L459.664 154.74L419.794 234.48H313.87L274 154.74L313.87 75ZM320.05 85L285.18 154.74L320.05 224.48H413.614L448.484 154.74L413.614 85H320.05Z"
        fill="#D4A168" />
</template>