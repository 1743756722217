<template>
    <path id="Vector (Stroke)_34" fill-rule="evenodd" clip-rule="evenodd"
        d="M321.519 533H413.031L447.547 602.033L413.031 671.065H321.513L287 601.891L321.519 533ZM328.232 543.871L299.154 601.902L328.238 660.194H406.312L435.393 602.033L406.312 543.871H328.232Z"
        fill="#E8D9AA" />
    <path id="Vector (Stroke)_35" fill-rule="evenodd" clip-rule="evenodd"
        d="M321.519 384H413.031L447.547 453.033L413.031 522.065H321.513L287 452.891L321.519 384ZM328.232 394.871L299.154 452.902L328.238 511.194H406.312L435.393 453.033L406.312 394.871H328.232Z"
        fill="#E8D9AA" />
    <path id="Vector (Stroke)_36" fill-rule="evenodd" clip-rule="evenodd"
        d="M321.519 235H413.031L447.547 304.033L413.031 373.065H321.513L287 303.891L321.519 235ZM328.232 245.871L299.154 303.902L328.238 362.194H406.312L435.393 304.033L406.312 245.871H328.232Z"
        fill="#E8D9AA" />
    <path id="Vector_37" d="M280 899.74L317.37 825H417.114L454.484 899.74L417.114 974.48H317.37L280 899.74Z"
        fill="#D4A168" />
    <path id="Vector (Stroke)_37" fill-rule="evenodd" clip-rule="evenodd"
        d="M313.87 820H419.794L459.664 899.74L419.794 979.48H313.87L274 899.74L313.87 820ZM320.05 830L285.18 899.74L320.05 969.48H413.614L448.484 899.74L413.614 830H320.05Z"
        fill="#D4A168" />
    <path id="Vector_38" d="M280 750.74L317.37 676H417.114L454.484 750.74L417.114 825.48H317.37L280 750.74Z"
        fill="#D4A168" />
    <path id="Vector (Stroke)_38" fill-rule="evenodd" clip-rule="evenodd"
        d="M313.87 671H419.794L459.664 750.74L419.794 830.48H313.87L274 750.74L313.87 671ZM320.05 681L285.18 750.74L320.05 820.48H413.614L448.484 750.74L413.614 681H320.05Z"
        fill="#D4A168" />
    <path id="Vector_39" d="M280 1048.74L317.37 974H417.114L454.484 1048.74L417.114 1123.48H317.37L280 1048.74Z"
        fill="#D4A168" />
    <path id="Vector (Stroke)_39" fill-rule="evenodd" clip-rule="evenodd"
        d="M313.87 969H419.794L459.664 1048.74L419.794 1128.48H313.87L274 1048.74L313.87 969ZM320.05 979L285.18 1048.74L320.05 1118.48H413.614L448.484 1048.74L413.614 979H320.05Z"
        fill="#D4A168" />
</template>