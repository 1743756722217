<template>
    <path id="Vector (Stroke)_6" fill-rule="evenodd" clip-rule="evenodd"
        d="M48.5194 384H140.031L174.547 453.033L140.031 522.065H48.513L14 452.891L48.5194 384ZM55.2318 394.871L26.1544 452.902L55.2383 511.194H133.312L162.393 453.033L133.312 394.871H55.2318Z"
        fill="#E8D9AA" />
    <path id="Vector_7" d="M7 601.74L44.3699 527H144.114L181.484 601.74L144.114 676.48H44.3699L7 601.74Z" fill="#D4A168" />
    <path id="Vector (Stroke)_7" fill-rule="evenodd" clip-rule="evenodd"
        d="M40.8699 522H146.794L186.664 601.74L146.794 681.48H40.8699L1 601.74L40.8699 522ZM47.0503 532L12.1803 601.74L47.0503 671.48H140.614L175.484 601.74L140.614 532H47.0503Z"
        fill="#D4A168" />
    <path id="Vector_8" d="M7 750.74L44.3699 676H144.114L181.484 750.74L144.114 825.48H44.3699L7 750.74Z" fill="#D4A168" />
    <path id="Vector (Stroke)_8" fill-rule="evenodd" clip-rule="evenodd"
        d="M40.8699 671H146.794L186.664 750.74L146.794 830.48H40.8699L1 750.74L40.8699 671ZM47.0503 681L12.1803 750.74L47.0503 820.48H140.614L175.484 750.74L140.614 681H47.0503Z"
        fill="#D4A168" />
    <path id="Vector_9" d="M7 899.74L44.3699 825H144.114L181.484 899.74L144.114 974.48H44.3699L7 899.74Z" fill="#D4A168" />
    <path id="Vector (Stroke)_9" fill-rule="evenodd" clip-rule="evenodd"
        d="M40.8699 820H146.794L186.664 899.74L146.794 979.48H40.8699L1 899.74L40.8699 820ZM47.0503 830L12.1803 899.74L47.0503 969.48H140.614L175.484 899.74L140.614 830H47.0503Z"
        fill="#D4A168" />
</template>