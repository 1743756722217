<template>
    <path id="Vector (Stroke)_40" fill-rule="evenodd" clip-rule="evenodd"
        d="M321.519 682H413.031L447.547 751.033L413.031 820.065H321.513L287 750.891L321.519 682ZM328.232 692.871L299.154 750.902L328.238 809.194H406.312L435.393 751.033L406.312 692.871H328.232Z"
        fill="#E8D9AA" />
    <path id="Vector (Stroke)_41" fill-rule="evenodd" clip-rule="evenodd"
        d="M321.519 831H413.031L447.547 900.033L413.031 969.065H321.513L287 899.891L321.519 831ZM328.232 841.871L299.154 899.902L328.238 958.194H406.312L435.393 900.033L406.312 841.871H328.232Z"
        fill="#E8D9AA" />
    <path id="Vector (Stroke)_42" fill-rule="evenodd" clip-rule="evenodd"
        d="M321.519 980H413.031L447.547 1049.03L413.031 1118.07H321.513L287 1048.89L321.519 980ZM328.232 990.871L299.154 1048.9L328.238 1107.19H406.312L435.393 1049.03L406.312 990.871H328.232Z"
        fill="#E8D9AA" />
    <path id="Vector_43" d="M417 377.74L454.37 303H554.114L591.484 377.74L554.114 452.48H454.37L417 377.74Z"
        fill="#D4A168" />
    <path id="Vector (Stroke)_43" fill-rule="evenodd" clip-rule="evenodd"
        d="M450.87 298H556.794L596.664 377.74L556.794 457.48H450.87L411 377.74L450.87 298ZM457.05 308L422.18 377.74L457.05 447.48H550.614L585.484 377.74L550.614 308H457.05Z"
        fill="#D4A168" />
    <path id="Vector_44" d="M417 79.74L454.37 5H554.114L591.484 79.74L554.114 154.48H454.37L417 79.74Z" fill="#D4A168" />
    <path id="Vector (Stroke)_44" fill-rule="evenodd" clip-rule="evenodd"
        d="M450.87 0H556.794L596.664 79.74L556.794 159.48H450.87L411 79.74L450.87 0ZM457.05 10L422.18 79.74L457.05 149.48H550.614L585.484 79.74L550.614 10H457.05Z"
        fill="#D4A168" />
    <path id="Vector_45" d="M417 228.74L454.37 154H554.114L591.484 228.74L554.114 303.48H454.37L417 228.74Z"
        fill="#D4A168" />
    <path id="Vector (Stroke)_45" fill-rule="evenodd" clip-rule="evenodd"
        d="M450.87 149H556.794L596.664 228.74L556.794 308.48H450.87L411 228.74L450.87 149ZM457.05 159L422.18 228.74L457.05 298.48H550.614L585.484 228.74L550.614 159H457.05Z"
        fill="#D4A168" />
</template>