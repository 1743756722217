<template>
    <path id="Vector (Stroke)_10" fill-rule="evenodd" clip-rule="evenodd"
        d="M48.5194 831H140.031L174.547 900.033L140.031 969.065H48.513L14 899.891L48.5194 831ZM55.2318 841.871L26.1544 899.902L55.2383 958.194H133.312L162.393 900.033L133.312 841.871H55.2318Z"
        fill="#E8D9AA" />
    <path id="Vector (Stroke)_11" fill-rule="evenodd" clip-rule="evenodd"
        d="M48.5194 682H140.031L174.547 751.033L140.031 820.065H48.513L14 750.891L48.5194 682ZM55.2318 692.871L26.1544 750.902L55.2383 809.194H133.312L162.393 751.033L133.312 692.871H55.2318Z"
        fill="#E8D9AA" />
    <path id="Vector (Stroke)_12" fill-rule="evenodd" clip-rule="evenodd"
        d="M48.5194 533H140.031L174.547 602.033L140.031 671.065H48.513L14 601.891L48.5194 533ZM55.2318 543.871L26.1544 601.902L55.2383 660.194H133.312L162.393 602.033L133.312 543.871H55.2318Z"
        fill="#E8D9AA" />
    <path id="Vector_13" d="M6 1048.74L43.3699 974H143.114L180.484 1048.74L143.114 1123.48H43.3699L6 1048.74Z"
        fill="#D4A168" />
    <path id="Vector (Stroke)_13" fill-rule="evenodd" clip-rule="evenodd"
        d="M39.8699 969H145.794L185.664 1048.74L145.794 1128.48H39.8699L0 1048.74L39.8699 969ZM46.0503 979L11.1803 1048.74L46.0503 1118.48H139.614L174.484 1048.74L139.614 979H46.0503Z"
        fill="#D4A168" />
</template>