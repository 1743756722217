<template>
    <path id="Vector_49" d="M417 526.74L454.37 452H554.114L591.484 526.74L554.114 601.48H454.37L417 526.74Z"
        fill="#D4A168" />
    <path id="Vector (Stroke)_46" fill-rule="evenodd" clip-rule="evenodd"
        d="M450.87 447H556.794L596.664 526.74L556.794 606.48H450.87L411 526.74L450.87 447ZM457.05 457L422.18 526.74L457.05 596.48H550.614L585.484 526.74L550.614 457H457.05Z"
        fill="#D4A168" />
    <path id="Vector_50" d="M417 675.74L454.37 601H554.114L591.484 675.74L554.114 750.48H454.37L417 675.74Z"
        fill="#D4A168" />
    <path id="Vector (Stroke)_47" fill-rule="evenodd" clip-rule="evenodd"
        d="M450.87 596H556.794L596.664 675.74L556.794 755.48H450.87L411 675.74L450.87 596ZM457.05 606L422.18 675.74L457.05 745.48H550.614L585.484 675.74L550.614 606H457.05Z"
        fill="#D4A168" />
    <path id="Vector_51" d="M417 824.74L454.37 750H554.114L591.484 824.74L554.114 899.48H454.37L417 824.74Z"
        fill="#D4A168" />
    <path id="Vector (Stroke)_48" fill-rule="evenodd" clip-rule="evenodd"
        d="M450.87 745H556.794L596.664 824.74L556.794 904.48H450.87L411 824.74L450.87 745ZM457.05 755L422.18 824.74L457.05 894.48H550.614L585.484 824.74L550.614 755H457.05Z"
        fill="#D4A168" />
</template>