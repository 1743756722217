<template>
    <path id="Vector (Stroke)_30" fill-rule="evenodd" clip-rule="evenodd"
        d="M321.519 86H413.031L447.547 155.033L413.031 224.065H321.513L287 154.891L321.519 86ZM328.232 96.8713L299.154 154.902L328.238 213.194H406.312L435.393 155.033L406.312 96.8713H328.232Z"
        fill="#E8D9AA" />
    <path id="Vector_31" d="M280 601.74L317.37 527H417.114L454.484 601.74L417.114 676.48H317.37L280 601.74Z"
        fill="#D4A168" />
    <path id="Vector (Stroke)_31" fill-rule="evenodd" clip-rule="evenodd"
        d="M313.87 522H419.794L459.664 601.74L419.794 681.48H313.87L274 601.74L313.87 522ZM320.05 532L285.18 601.74L320.05 671.48H413.614L448.484 601.74L413.614 532H320.05Z"
        fill="#D4A168" />
    <path id="Vector_32" d="M280 303.74L317.37 229H417.114L454.484 303.74L417.114 378.48H317.37L280 303.74Z"
        fill="#D4A168" />
    <path id="Vector (Stroke)_32" fill-rule="evenodd" clip-rule="evenodd"
        d="M313.87 224H419.794L459.664 303.74L419.794 383.48H313.87L274 303.74L313.87 224ZM320.05 234L285.18 303.74L320.05 373.48H413.614L448.484 303.74L413.614 234H320.05Z"
        fill="#D4A168" />
    <path id="Vector_33" d="M280 452.74L317.37 378H417.114L454.484 452.74L417.114 527.48H317.37L280 452.74Z"
        fill="#D4A168" />
    <path id="Vector (Stroke)_33" fill-rule="evenodd" clip-rule="evenodd"
        d="M313.87 373H419.794L459.664 452.74L419.794 532.48H313.87L274 452.74L313.87 373ZM320.05 383L285.18 452.74L320.05 522.48H413.614L448.484 452.74L413.614 383H320.05Z"
        fill="#D4A168" />
</template>